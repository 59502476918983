import React from "react";

const RideCard = ({ img, title, date, description }) => {
  return (
    <div className="bg-white max-w-[450px]">
      <div className="relative h-[300px]">
        <img src={img} className="object-cover w-full h-full" />
        {/* <span className="absolute px-4 py-2 text-xl font-light text-white left-4 -bottom-4 bg-kr-yellow">{date}</span> */}
      </div>
      <h2 className="p-8 text-4xl font-regular">{title}</h2>
      <p className="px-8 pb-8 font-sans text-neutral-700">{description}</p>
    </div>
  );
};

export default RideCard;
