import Spiti from "../../images/rides/spiti.jpg";
import Ladakh from "../../images/rides/ladakh.jpg";
import Sikkim from "../../images/rides/sikkim.jpg";
import Zanskar from "../../images/rides/zanskar.jpg";
import Puri from "../../images/rides/puri.jpg";

export const rideData = [
  {
    img: Spiti,
    title: "A Ride to Spiti Valley",
    description:
      "",
    date: "April 17, 2019",
  },
  {
    img: Ladakh,
    title: "A Ride to Ladakh",
    description:
      "",
    date: "April 17, 2019",
  },
  {
    img: Sikkim,
    title: "A Ride to Sikkim",
    description:
      "",
    date: "April 17, 2019",
  },
  {
    img: Zanskar,
    title: "A Ride to Zanskar",
    description:
      "",
    date: "April 17, 2019",
  },
  {
    img: Puri,
    title: "A Ride to Puri",
    description:
      "",
    date: "April 17, 2019",
  },
];
