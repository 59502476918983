import React from "react";
import Layout from "../components/Layout/Layout";
import { rideData } from "../components/RideCard/ride-data";
import RideCard from "../components/RideCard/RideCard";

import Banner from "../images/misc/sikkim.jpg";

export default function RideReport() {
  return (
    <Layout>
      <div className="absolute top-0 left-0 h-[500px] w-full blur-[2px]">
        <img src={Banner} className="object-cover w-full h-full md:h-auto md:w-auto" />
      </div>
      <div className="container relative p-8 mx-auto text-center md:p-36">
        <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-yellow after:content-['♦'] after:text-kr-yellow before:pr-4 after:pl-4">
          RIDING REPORT
        </h6>
        <h1 className="my-4 font-semibold text-white uppercase text-7xl">
          OUR RIDES
        </h1>
      </div>
      <section className="relative pb-12 bg-neutral-800 md:py-36">
        <div className="container relative flex-col flex-wrap items-center px-4 mx-auto md:flex md:px-0">
          <div className="grid grid-cols-1 gap-8 py-12 md:grid-cols-3">
            {rideData.map((data, index) => {
              return (
                <RideCard
                  img={data.img}
                  title={data.title}
                  description={data.description}
                  date={data.date}
                />
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => <title>Ride Report</title>;
